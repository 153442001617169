import { extendTheme } from '@chakra-ui/react'
import "@fontsource/cardo/400.css"
import "@fontsource/lato/700.css"
import "@fontsource/lato/700-italic.css"
import "@fontsource/lato/400.css"
import "@fontsource/lato/400-italic.css"

const theme = {
  styles: {
    global: {
      html: {
        fontSize: '16px'
      }
    }
  },
  fonts: {
    heading: 'Poppins',
    body: 'Poppins',
  },
  colors: {
    primary: '#06273D',
    secondary: '#76d0ed'
  },
  fontSize: {
    xxs: "10px"
  }
}

export default extendTheme(theme)